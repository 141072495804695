import { ReactElement } from "react";
import Button from "@material-ui/core/Button";
import { Flex } from "@theme-ui/components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  ctaButtonOne: {
    marginRight: "0.5rem",
    height: "60px",
    width: "100%",
    borderRadius: "6px",
    backgroundColor: "hsl(99, 47%, 56%)",
    boxShadow: "4px 4px 10px 5px rgb(0 0 0 / 12%)",
    WebkitTransition: "-webkit-transform 200ms ease",
    transition: "-webkit-transform 200ms ease",
    fontFamily: "Poppins, sans-serif",
    fontSize: "0.8rem",
    textAlign: "center",
    color: "white",
    "&:hover": {
      backgroundColor: "hsl(218,57%,27%)",
    },
  },
  ctaButtonTwo: {
    // marginTop: "2rem",
    marginLeft: "0.5rem",
    width: "100%",
    height: "60px",
    borderRadius: "6px",
    backgroundColor: "hsl(99, 47%, 56%)",
    boxShadow: "4px 4px 10px 5px rgb(0 0 0 / 12%)",
    WebkitTransition: "-webkit-transform 200ms ease",
    transition: "-webkit-transform 200ms ease",
    fontFamily: "Poppins, sans-serif",
    fontSize: "0.8rem",
    textAlign: "center",
    color: "white",
    "&:hover": {
      backgroundColor: "hsl(218,57%,27%)",
    },
  },
});

export default function ButtonsRow(): ReactElement {
  const classes = useStyles();

  return (
    <Flex
      sx={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        mt: 6,
        maxWidth: "400px",
        paddingX: [4],
        marginX: "auto",
      }}
    >
      <Button
        className={classes.ctaButtonOne}
        variant="contained"
        type="submit"
        href="mailto:support@xeroe.co.uk"
      >
        GET IN TOUCH
      </Button>
      <Button
        className={classes.ctaButtonTwo}
        variant="contained"
        type="submit"
        href="mailto:savings@xeroe.co.uk"
      >
        GET DETAILED REPORT
      </Button>
    </Flex>
  );
}
