import { ReactElement } from "react";
import { Flex, Text } from "@theme-ui/components";
import { ReportType } from "src/types/Report.Type";
import CommaNumber from "comma-number";

interface Props {
  reportObj: ReportType;
}

const tableHeaderFontSize = {
  fontSize: ["0.7rem", "0.7rem", "0.7rem", "0.65rem"],
};

const tableRowHeight = {
  height: ["75px"],
};

const tableTitleFontSize = {
  fontSize: ["0.6rem", "0.6rem", "0.7rem", "0.7rem", "0.8rem"],
};

const tableNumberFontSize = {
  fontSize: ["0.6rem", "0.6rem", "0.6rem", "0.6rem", "0.8rem"],
};

const firstColumnShared = {
  ...tableHeaderFontSize,
  minWidth: ["100px", "100px", "100px", "110px"],
  width: ["100px", "100px", "110px", "120px"],
  paddingLeft: "1rem",
  paddingRight: "1rem",
  height: "100%",
};

const tableHeaderFlex = {
  height: "50px",
  borderTop: "1px solid #8fcb6f",
  // borderBottom: "1px solid #8fcb6f",
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-start",
};

const tableHeaderText = {
  ...tableHeaderFontSize,
  height: "100%",
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-start",
  fontWeight: "bold",
  fontFamily: "Poppins",
};

const headerFirstColumnFlexEmptyCell = {
  ...firstColumnShared,
  alignItems: "center",
  justifyContent: "flex-start",
  fontWeight: "bold",
  fontFamily: "Poppins",
};

const traditionalDeliveryTitle = {
  ...tableTitleFontSize,
  ...firstColumnShared,
  alignItems: "center",
  justifyContent: "flex-start",
  fontWeight: "bold",
  color: "white",
  fontFamily: "Poppins",
};

const xeroeDeliveryTitle = {
  ...tableTitleFontSize,
  ...firstColumnShared,
  alignItems: "center",
  justifyContent: "flex-start",
  fontWeight: "bold",
  fontFamily: "Poppins",
};

const savingSummaryTitle = {
  ...tableTitleFontSize,
  ...firstColumnShared,
  alignItems: "center",
  justifyContent: "flex-start",
  fontWeight: "bold",
  fontFamily: "Poppins",
};
const tableRowTraditionalFlex = {
  ...tableRowHeight,
  width: "100%",
  borderTop: "1px solid #8fcb6f",
  paddingY: "0.5rem",
  // borderBottom: "1px solid #8fcb6f",
  backgroundColor: "#0f2930",
};
const tableRowXeroeFlex = {
  ...tableRowHeight,
  width: "100%",
  borderTop: "1px solid #8fcb6f",
  borderBottom: "1px solid #8fcb6f",
  paddingY: "0.5rem",
};

const tableRowSavingFlex = {
  ...tableRowHeight,
  width: "100%",
  paddingY: "0.5rem",
  // borderTop: "1px solid #8fcb6f",
  borderBottom: "1px solid #8fcb6f",
  backgroundImage:
    "linear-gradient( 109.6deg,  rgba(229,233,236,1) 1.8%, rgba(246,237,229,1) 50.6%, rgba(161,224,186,1) 100.3% )",
};

const darkRowNumberFlex = {
  height: "100%",
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-start",
  color: "white",
};

const lightRowNumberFlex = {
  height: "100%",
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-start",
};

const lightNumberText = {
  ...tableNumberFontSize,
  color: "white",
};

const darkNumberText = {
  ...tableNumberFontSize,
  color: "textDark",
  fontFamily: "Jubilat-Regular",
};

export default function EmissionsTable({ reportObj }: Props): ReactElement {
  return (
    <Flex
      sx={{
        // border: "1px solid red",
        // borderTop: "1px solid green",
        // borderBottom: "1px solid green",
        marginTop: "2rem",
        // height: "250px",
        flexDirection: "column",
      }}
    >
      <Flex sx={{ width: "100%", height: "100%" }}>
        <Flex sx={tableHeaderFlex}>
          <Flex sx={headerFirstColumnFlexEmptyCell}> </Flex>
          <Flex sx={tableHeaderText}>CO2</Flex>
          <Flex sx={tableHeaderText}>NOX</Flex>
          <Flex sx={tableHeaderText}>PM2.5</Flex>
          <Flex sx={{ ...tableHeaderText, display: ["none !important", "flex !important"] }}>
            NHS Cost
          </Flex>
        </Flex>
      </Flex>
      <Flex sx={tableRowTraditionalFlex}>
        <Flex sx={traditionalDeliveryTitle}>{"TRADITIONAL DELIVERY"}</Flex>
        <Flex sx={darkRowNumberFlex}>
          <Text as="p" sx={lightNumberText}>
            {`${CommaNumber(
              Number(reportObj?.report?.totals?.traditional?.co2?.value).toFixed(2)
            )}`}{" "}
            kg
          </Text>
        </Flex>
        <Flex sx={darkRowNumberFlex}>
          <Text as="p" sx={lightNumberText}>{`${CommaNumber(
            Number(reportObj?.report?.totals?.traditional?.nox?.value).toFixed(2)
          )} g`}</Text>
        </Flex>
        <Flex sx={darkRowNumberFlex}>
          <Text as="p" sx={lightNumberText}>{`${CommaNumber(
            Number(reportObj?.report?.totals?.traditional?.["pm2.5"]?.value).toFixed(2)
          )} g`}</Text>
        </Flex>
        <Flex
          sx={{
            ...darkRowNumberFlex,
            display: ["none !important", "flex !important"],
          }}
        >
          <Text as="p" sx={lightNumberText}>{`${
            reportObj?.report?.totals?.traditional?.nhs?.unit
          }${CommaNumber(
            Number(reportObj?.report?.totals?.traditional?.nhs?.value).toFixed(2)
          )}`}</Text>
        </Flex>
      </Flex>
      <Flex sx={tableRowXeroeFlex}>
        <Flex sx={xeroeDeliveryTitle}>{"XEROE DELIVERY"}</Flex>
        <Flex sx={lightRowNumberFlex}>
          <Text as="p" sx={darkNumberText}>{`${CommaNumber(
            Number(reportObj?.report?.totals?.xeroe?.co2?.value).toFixed(2)
          )} kg`}</Text>
        </Flex>
        <Flex sx={lightRowNumberFlex}>
          <Text as="p" sx={darkNumberText}>{`${CommaNumber(
            Number(reportObj?.report?.totals?.xeroe?.nox?.value).toFixed(2)
          )} g`}</Text>
        </Flex>
        <Flex sx={lightRowNumberFlex}>
          <Text as="p" sx={darkNumberText}>{`${CommaNumber(
            Number(reportObj?.report?.totals?.xeroe["pm2.5"]?.value).toFixed(2)
          )} g`}</Text>
        </Flex>
        <Flex
          sx={{
            ...lightRowNumberFlex,
            display: ["none !important", "flex !important"],
          }}
        >
          <Text as="p" sx={darkNumberText}>{`${
            reportObj?.report?.totals?.xeroe?.nhs?.unit
          }${CommaNumber(Number(reportObj?.report?.totals?.xeroe?.nhs?.value).toFixed(2))}`}</Text>
        </Flex>
      </Flex>
      <Flex sx={tableRowSavingFlex}>
        <Flex sx={savingSummaryTitle}>{"SAVING SUMMARY"}</Flex>
        <Flex sx={lightRowNumberFlex}>
          <Text as="p" sx={darkNumberText}>{`${CommaNumber(
            Number(reportObj?.report?.savings?.co2?.value).toFixed(2)
          )} kg`}</Text>
        </Flex>
        <Flex sx={lightRowNumberFlex}>
          <Text as="p" sx={darkNumberText}>{`${CommaNumber(
            Number(reportObj?.report?.savings?.nox?.value).toFixed(2)
          )} g`}</Text>
        </Flex>
        <Flex sx={lightRowNumberFlex}>
          <Text as="p" sx={darkNumberText}>{`${CommaNumber(
            Number(reportObj?.report?.savings["pm2.5"]?.value).toFixed(2)
          )} g`}</Text>
        </Flex>
        <Flex
          sx={{
            ...lightRowNumberFlex,
            display: ["none !important", "flex !important"],
          }}
        >
          <Text as="p" sx={darkNumberText}>{`${
            reportObj?.report?.totals?.xeroe?.nhs?.unit
          }${CommaNumber(Number(reportObj?.report?.savings?.nhs?.value).toFixed(2))}`}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
