import { ThemeUIContextValue, useThemeUI } from "theme-ui";

import { Theme } from "@theme-ui/css";

interface ExactContextValue extends Omit<ThemeUIContextValue, "theme"> {
  theme: ExactTheme;
}

export const themeUI: Theme = {
  useColorSchemeMediaQuery: false,
  printColorModeName: "light",
  useLocalStorage: true,
  breakpoints: [
    "339px",
    "370px",
    "400px",
    "600px",
    "900px",
    "1200px",
    "1400px",
    "1800px",
    "2200px",
  ],
  space: [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256, 512],
  fonts: {
    heading: '"Poppins", sans-serif',
    body: '"Poppins", sans-serif',
    text: '"Poppins", sans-serif',
  },
  fontSizes: [
    "0.422rem",
    "0.563rem",
    "0.75rem",
    "1rem",
    "1.333rem",
    "1.777rem",
    "2.369rem",
    "3.157rem",
    "4.209rem",
    "5.61rem",
  ], // Perfect 4th Scale
  fontWeights: {
    body: 400,
    mid: 500,
    heading: 600,
    bold: 700,
  },
  lineHeights: {
    heading: 1.25,
    body: 1.5,
  },
  // palette: {
  //   mode: "light",
  // },
  colors: {
    highlight: "hsl(155, 20%, 44%)",
    muted: "hsl(288, 54%, 79%)",
    accent: "hsl(182, 77%, 43%)",

    action: "#5CAB87",
    primary100: "hsl(148, 25%, 66%)",
    primary150: "hsl(148, 25%, 55%)",
    primary200: "hsl(155, 20%, 44%)",
    primary250: "hsl(155, 30%, 54%)",
    primary300: "hsl(148, 25%, 22%)",

    secondary100: "hsl(263, 33%, 58%)",
    secondary200: "hsl(263, 33%, 48%)",
    secondary300: "hsl(263, 33%, 38%)",

    accent100: "hsl(182, 77%, 53%)",
    accent200: "hsl(182, 77%, 43%)",
    accent300: "hsl(182, 77%, 33%)",

    muted100: "hsl(288, 54%, 89%)",
    muted200: "hsl(288, 54%, 79%)",
    muted300: "hsl(288, 54%, 69%)",

    cream: "hsl(42, 52%, 90%)",

    neutral100: "hsl(0, 0%, 100%)",
    neutral200: "hsl(84, 17%, 94%)",
    neutral300: "hsl(42, 52%, 90%)",
    neutral310: "hsl(240, 1%, 80%)",
    neutral312: "hsl(240, 1%, 75%)",
    neutral315: "hsl(240, 1%, 70%)",
    neutral325: "hsl(240, 1%, 60%)",
    neutral350: "hsl(240, 1%, 50%)",
    neutral400: "hsl(240, 1%, 40%)",
    neutral500: "hsl(240, 1%, 25%)",
    neutral600: "hsl(240, 1%, 19%)",
    neutral700: "#141414",
    faded: "hsl(240, 1%, 60%)",
    mutedBlack: "hsl(240, 1%, 19%)",

    black: "hsl(0, 0%, 0%)",
    white: "hsl(0, 0%, 100%)",

    error100: "hsl(4, 87%, 60%)",
    error200: "hsl(4, 87%, 50%)",

    sucess100: "hsl(148, 40%, 50%)",
    sucess200: "hsl(148, 40%, 40%)",

    info100: "hsl(221, 48%, 50%)",
    info200: "hsl(221, 48%, 40%)",

    warning100: "hsl(148, 100%, 40%)",
    warning200: "hsl(148, 100%, 60%)",

    text: "hsl(0, 0%, 100%)",
    textLight: "hsl(0, 0%, 100%)",
    textDark: "hsl(240, 1%, 12%)",
    primary: "hsl(148, 30%, 40%)",
    secondary: "hsl(263, 33%, 48%)",
    body: "hsl(0, 5%, 10%)",
    background: "hsl(0, 100%, 100%)",
    backgroundLight: "hsl(0, 100%, 100%)",
    backgroundDark: "hsl(0, 100%, 100%)",
    topNavBackground: "hsl(0, 5%, 5%)",
    topNavText: "hsl(0, 0%, 100%)",
    topNavLogo: "hsl(0, 100%, 100%)",
    topNavDivider: "rgba(255, 255, 255, 0.4)",
    mainBackground: "hsl(0, 5%, 5%)",
    navDivider: "rgba(255, 255, 255, 0.4)",
    // background nav bar start
    bottomNavBackground: "hsl(100, 18%, 17%)",
    bottomNavBackgroundSelected: "primary200",
    // background nav bar start
    bottomNavText: "hsl(0, 0%, 100%)",
    bottomNavIcon: "hsl(0, 0%, 100%)",
    bottomNavIconSelected: "hsl(0, 0%, 100%)",
    bottomNavIconSelectedBackground: "hsl(0, 0%, 10%)",
    bottomNavDivider: "rgba(255, 255, 255, 0.4)",
    sideDrawerBackground: "hsl(0, 5%, 10%)",
    sideDrawerText: "hsl(0, 0%, 100%)",
    sideDrawerNavDivider: "hsla(0, 0%, 100%, 0.25)",
    footerPublicBackground: "hsl(0, 5%, 5%)",
    footerPublicInput: "#fff",
    footerPublicSelected: "green",
    footerPublicBannerColor: "hsl(240, 1%, 19%)",
    footerPublicBannerIconColor: "hsl(0, 100%, 100%)",
    jobFilterBannerColor: "#fff",
    jobFilterBannerIconColor: "#000",
    modes: {
      light: {
        text: "hsl(0, 0%, 100%)",
        textLight: "hsl(0, 0%, 100%)",
        textDark: "hsl(240, 1%, 19%)",
        primary: "hsl(148, 30%, 40%)",
        secondary: "hsl(263, 33%, 48%)",
        body: "hsl(0, 0%, 100%)",
        navDivider: "rgba(255, 255, 255, 0.4)",
        topNavBackground: "hsl(0, 5%, 5%)",
        topNavText: "hsl(0, 0%, 100%)",
        topNavLogo: "hsl(0, 100%, 100%)",
        topNavDivider: "rgba(255, 255, 255, 0.4)",
        mainBackground: "hsl(0, 5%, 5%)",
        bottomNavBackground: "hsl(0, 0%, 10%)",
        bottomNavText: "hsl(0, 0%, 100%)",
        bottomNavIcon: "hsl(0, 0%, 100%)",
        bottomNavIconSelected: "hsl(0, 0%, 100%)",
        bottomNavIconSelectedBackground: "hsl(0, 0%, 10%)",
        bottomNavDivider: "rgba(255, 255, 255, 0.4)",
        sideDrawerBackground: "hsl(0, 5%, 10%)",
        sideDrawerText: "hsl(0, 0%, 100%)",
        sideDrawerNavDivider: "hsla(0, 0%, 100%, 0.25)",
        footerPublicBackground: "hsl(0, 5%, 5%)",
        footerPublicInput: "#fff",
        footerPublicSelected: "green",
        footerPublicBannerColor: "hsl(240, 1%, 19%)",
        footerPublicBannerIconColor: "hsl(0, 100%, 100%)",
      },
    },
  },
  styles: {
    root: {
      color: "textLight",
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      color: "textLight",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 6,
      userSelect: "none",
    },
    h2: {
      color: "textLight",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 5,
      userSelect: "none",
    },
    h3: {
      color: "textLight",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 4,
      userSelect: "none",
    },
    h4: {
      color: "textLight",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 4,
      userSelect: "none",
    },
    h5: {
      color: "textLight",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 2,
      userSelect: "none",
    },
    h6: {
      color: "textLight",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 1,
      userSelect: "none",
    },
    p: {
      color: "textLight",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    a: {
      color: "textLight",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
  },
  buttons: {
    contained: {
      width: "100%",
      textTransform: "uppercase",
      // whiteSpace: "none",
      userSelect: "none",
      borderWidth: 1,
      borderRadius: 9999,
      borderStyle: "solid",
      borderColor: "action",
      color: "background",
      backgroundColor: "action",
      fontSize: 3,
      textDecoration: "none",
      fontFamily: "brand",
      outline: "none",
      px: 4,
      mr: "-0.15rem",
      "&:hover": {
        backgroundColor: "text",
        color: "action",
        outline: "none",
      },
      "&:active": {
        color: "primary",
        backgroundColor: "text",
        outline: "none",
      },
    },
    outlined: {
      text: {
        userSelect: "none",
        color: "text",
        bg: "transparent",
        textTransform: "capitalize",

        verticalAlign: "middle",
        lineHeight: 1.8,
        fontFamily: "brand",
        fontWeight: "500",

        borderWidth: 1,
        borderRadius: 0,
        borderColor: "text",
        borderStyle: "solid",

        pt: 0,
        pb: 0,
        pl: 2,
        pr: 2,

        boxShadow:
          "0 1.9px 2.2px -7px rgba(0, 0, 0, 0.02), 0 4.8px 5.3px -7px rgba(0, 0, 0, 0.028),\n    0 8.7px 10px -7px rgba(0, 0, 0, 0.035), 0 14.2px 17.9px -7px rgba(0, 0, 0, 0.042),\n    0 22.2px 33.4px -7px rgba(0, 0, 0, 0.05), 0 40px 80px -7px rgba(0, 0, 0, 0.07)",

        // '&:hover': {
        //   color: 'rbiGreen',
        //   bg: 'muted',
        //   fontFamily: 'body',
        // },

        // '&:active': {
        //   color: 'textLight',
        //   bg: 'muted',
        //   fontFamily: 'body',
        // },
      },
      primary: {
        userSelect: "none",
        color: "text",
        bg: "transparent",
        textTransform: "capitalize",

        verticalAlign: "middle",
        lineHeight: 1.8,
        fontFamily: "brand",
        fontWeight: "bold",

        borderWidth: 1,
        borderRadius: 0,
        borderStyle: "solid",
        borderColor: "text",

        pt: 0,
        pb: 0,
        pl: 2,
        pr: 2,

        boxShadow:
          "0 1.9px 2.2px -7px rgba(0, 0, 0, 0.02), 0 4.8px 5.3px -7px rgba(0, 0, 0, 0.028),\n    0 8.7px 10px -7px rgba(0, 0, 0, 0.035), 0 14.2px 17.9px -7px rgba(0, 0, 0, 0.042),\n    0 22.2px 33.4px -7px rgba(0, 0, 0, 0.05), 0 40px 80px -7px rgba(0, 0, 0, 0.07)",

        // '&:hover': {
        //   color: 'rbiGreen',
        //   bg: 'muted',
        //   fontFamily: 'body',
        // },

        // '&:active': {
        //   color: 'textLight',
        //   bg: 'muted',
        //   fontFamily: 'body',
        // },
      },
      primary100: {
        userSelect: "none",
        color: "primary100",
        bg: "transparent",
        textTransform: "capitalize",

        verticalAlign: "middle",
        lineHeight: 1.8,
        fontFamily: "brand",
        fontWeight: "bold",

        borderWidth: 1,
        borderRadius: 0,
        borderColor: "primary100",
        borderStyle: "solid",

        pt: 0,
        pb: 0,
        pl: 2,
        pr: 2,

        boxShadow:
          "0 1.9px 2.2px -7px rgba(0, 0, 0, 0.02), 0 4.8px 5.3px -7px rgba(0, 0, 0, 0.028),\n    0 8.7px 10px -7px rgba(0, 0, 0, 0.035), 0 14.2px 17.9px -7px rgba(0, 0, 0, 0.042),\n    0 22.2px 33.4px -7px rgba(0, 0, 0, 0.05), 0 40px 80px -7px rgba(0, 0, 0, 0.07)",

        // '&:hover': {
        //   color: 'rbiGreen',
        //   bg: 'muted',
        //   fontFamily: 'body',
        // },

        // '&:active': {
        //   color: 'textLight',
        //   bg: 'muted',
        //   fontFamily: 'body',
        // },
        "&:disabled": {
          color: "neutral325",
          borderColor: "neutral310",
          bg: "neutral200",
          fontFamily: "body",
        },
      },
      primary200: {
        userSelect: "none",
        color: "primary200",
        bg: "transparent",
        textTransform: "capitalize",

        verticalAlign: "middle",
        lineHeight: 1.8,
        fontFamily: "brand",
        fontWeight: "bold",

        borderWidth: 1,
        borderRadius: 0,
        borderColor: "primary200",
        borderStyle: "solid",

        pt: 0,
        pb: 0,
        pl: 2,
        pr: 2,

        boxShadow:
          "0 1.9px 2.2px -7px rgba(0, 0, 0, 0.02), 0 4.8px 5.3px -7px rgba(0, 0, 0, 0.028),\n    0 8.7px 10px -7px rgba(0, 0, 0, 0.035), 0 14.2px 17.9px -7px rgba(0, 0, 0, 0.042),\n    0 22.2px 33.4px -7px rgba(0, 0, 0, 0.05), 0 40px 80px -7px rgba(0, 0, 0, 0.07)",

        // '&:hover': {
        //   color: 'rbiGreen',
        //   bg: 'muted',
        //   fontFamily: 'body',
        // },

        // '&:active': {
        //   color: 'textLight',
        //   bg: 'muted',
        //   fontFamily: 'body',
        // },
      },
      primary300: {
        userSelect: "none",
        color: "primary300",
        bg: "transparent",
        textTransform: "capitalize",

        verticalAlign: "middle",
        lineHeight: 1.8,
        fontFamily: "brand",
        fontWeight: "bold",

        borderWidth: 1,
        borderRadius: 0,
        borderColor: "primary300",
        borderStyle: "solid",

        pt: 0,
        pb: 0,
        pl: 2,
        pr: 2,

        boxShadow:
          "0 1.9px 2.2px -7px rgba(0, 0, 0, 0.02), 0 4.8px 5.3px -7px rgba(0, 0, 0, 0.028),\n    0 8.7px 10px -7px rgba(0, 0, 0, 0.035), 0 14.2px 17.9px -7px rgba(0, 0, 0, 0.042),\n    0 22.2px 33.4px -7px rgba(0, 0, 0, 0.05), 0 40px 80px -7px rgba(0, 0, 0, 0.07)",

        // '&:hover': {
        //   color: 'rbiGreen',
        //   bg: 'muted',
        //   fontFamily: 'body',
        // },

        // '&:active': {
        //   color: 'textLight',
        //   bg: 'muted',
        //   fontFamily: 'body',
        // },
      },
      textLight: {
        userSelect: "none",
        color: "textLight",
        bg: "transparent",
        textTransform: "capitalize",

        verticalAlign: "middle",
        lineHeight: 1.8,
        fontFamily: "brand",
        fontWeight: "500",

        borderColor: "textLight",
        borderWidth: 1,
        borderRadius: 0,
        borderStyle: "solid",

        pt: 0,
        pb: 0,
        pl: 2,
        pr: 2,

        boxShadow:
          "0 1.9px 2.2px -7px rgba(0, 0, 0, 0.02), 0 4.8px 5.3px -7px rgba(0, 0, 0, 0.028),\n    0 8.7px 10px -7px rgba(0, 0, 0, 0.035), 0 14.2px 17.9px -7px rgba(0, 0, 0, 0.042),\n    0 22.2px 33.4px -7px rgba(0, 0, 0, 0.05), 0 40px 80px -7px rgba(0, 0, 0, 0.07)",

        // '&:hover': {
        //   color: 'rbiGreen',
        //   bg: 'muted',
        //   fontFamily: 'body',
        // },

        "&:active": {
          color: "textLight",
          fontFamily: "body",
          borderColor: "textLight",
          borderWidth: 1,
          borderRadius: 0,
          borderStyle: "solid",
        },
      },

      secondary: {
        userSelect: "none",
        color: "secondary",
        bg: "transparent",
        fontFamily: "body",
        borderWidth: 1,
        borderRadius: 0,
        borderStyle: "solid",
        borderColor: "secondary",
        boxShadow:
          "0 1.9px 2.2px -7px rgba(0, 0, 0, 0.02), 0 4.8px 5.3px -7px rgba(0, 0, 0, 0.028),\n    0 8.7px 10px -7px rgba(0, 0, 0, 0.035), 0 14.2px 17.9px -7px rgba(0, 0, 0, 0.042),\n    0 22.2px 33.4px -7px rgba(0, 0, 0, 0.05), 0 40px 80px -7px rgba(0, 0, 0, 0.07)",
        "&:hover": {
          color: "secondary",
          bg: "muted",
          fontFamily: "body",
        },
        "&:active": {
          color: "secondary",
          bg: "muted",
          fontFamily: "body",
        },
      },
      navButton: {
        color: "navText",
        bg: "transparent",
        fontFamily: "brand",
        fontWeight: "500",
        borderWidth: "0.114rem",
        borderColor: "navText",
        borderStyle: "solid",
        lineHeight: "normal",
        px: 3,
        py: 2,
        boxShadow:
          "0 1.9px 2.2px -7px rgba(0, 0, 0, 0.02), 0 4.8px 5.3px -7px rgba(0, 0, 0, 0.028),\n    0 8.7px 10px -7px rgba(0, 0, 0, 0.035), 0 14.2px 17.9px -7px rgba(0, 0, 0, 0.042),\n    0 22.2px 33.4px -7px rgba(0, 0, 0, 0.05), 0 40px 80px -7px rgba(0, 0, 0, 0.07)",
        "&:hover": {
          color: "navText",
          bg: "transparent",
          fontFamily: "brand",
        },
        "&:active": {
          color: "navText",
          bg: "muted",
          fontFamily: "brand",
        },
      },
    },
    text: {
      fontFamily: "brand",
      lineHeight: "body",
      fontWeight: "body",
      userSelect: "none",
      outline: "none",
      textAlign: "center",
      textDecoration: "none",
      color: "black",
      backgroundColor: "transparent",
      borderRadius: 9999,
      "&:hover": {
        cursor: "pointer",
      },
      // '&:hover': {
      //   color: 'rbiGreen',
      //   bg: 'muted',
      //   fontFamily: 'body',
      // },

      // '&:active': {
      //   color: 'textLight',
      //   bg: 'muted',
      //   fontFamily: 'body',
      // },
    },
    topNav: {
      fontFamily: "brand",
      lineHeight: "body",
      fontWeight: "body",
      userSelect: "none",
      outline: "none",
      textTransform: "uppercase",
      textDecoration: "none",
      color: "black",
      backgroundColor: "action",
      borderRadius: 9999,
      "&:hover": {
        cursor: "pointer",
      },
    },
    textOnlyInverted: {
      userSelect: "none",
      outline: "none",
      color: "textLight",
      bg: "transparent",
      textTransform: "capitalize",

      verticalAlign: "middle",
      lineHeight: 1.8,
      fontFamily: "brand",
      fontWeight: "500",

      borderWidth: 0,
      borderRadius: 0,
      borderColor: "textLight",
      borderStyle: "solid",

      pt: 0,
      pb: 0,
      pl: 2,
      pr: 2,

      boxShadow:
        "0 1.9px 2.2px -7px rgba(0, 0, 0, 0.02), 0 4.8px 5.3px -7px rgba(0, 0, 0, 0.028),\n    0 8.7px 10px -7px rgba(0, 0, 0, 0.035), 0 14.2px 17.9px -7px rgba(0, 0, 0, 0.042),\n    0 22.2px 33.4px -7px rgba(0, 0, 0, 0.05), 0 40px 80px -7px rgba(0, 0, 0, 0.07)",

      // '&:hover': {
      //   color: 'rbiGreen',
      //   bg: 'muted',
      //   fontFamily: 'body',
      // },

      // '&:active': {
      //   color: 'textLight',
      //   bg: 'muted',
      //   fontFamily: 'body',
      // },
    },
    close: {
      color: "primary",
      fontFamily: "brand",
    },
    jobsListCardApplyButton: {
      backgroundColor: "primary200",
      color: "neutral100",
      borderRadius: 0,
      fontFamily: "brand",
    },
    sideDrawerMenuItemATag: {
      fontFamily: "brand",
      backgroundColor: "transparent",
    },
  },
  text: {
    default: {
      color: "textLight",
      fontSize: 3,
    },
    dark: {
      color: "textLight",
      fontSize: 3,
    },
    light: {
      color: "text",
      fontSize: 3,
    },
    bold: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "bold",
    },
    underlined: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "bold",
      textDecorationLine: "underline",
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.2em",
    },
    capsBold: {
      textTransform: "uppercase",
      letterSpacing: "0.2em",
      fontWeight: "bold",
    },
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
    },
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    body: {
      color: "textLight",
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      light: {
        color: "text",
        fontFamily: "body",
        lineHeight: "body",
        fontWeight: "body",
      },
    },
    h1: {
      color: "textLight",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 7,
      light: {
        color: "text",
        fontFamily: "heading",
        lineHeight: "heading",
        fontWeight: "heading",
        fontSize: 7,
      },
    },
    h2: {
      fontSize: 6,
      color: "textLight",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      light: {
        color: "text",
        fontFamily: "heading",
        lineHeight: "heading",
        fontWeight: "heading",
        fontSize: 6,
      },
    },
    h3: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 5,
      color: "textLight",
      light: {
        fontFamily: "heading",
        lineHeight: "heading",
        fontWeight: "heading",
        fontSize: 5,
        color: "text",
      },
    },
    h4: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 4,
      color: "textLight",
      light: {
        fontFamily: "heading",
        lineHeight: "heading",
        fontWeight: "heading",
        fontSize: 4,
        color: "text",
      },
    },
    h5: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 3,
      color: "textLight",
      light: {
        fontFamily: "heading",
        lineHeight: "heading",
        fontWeight: "heading",
        fontSize: 3,
        color: "text",
      },
    },
    h6: {
      color: "textLight",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 2,
      light: {
        color: "text",
        fontFamily: "heading",
        lineHeight: "heading",
        fontWeight: "heading",
        fontSize: 2,
      },
    },
    p: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      color: "textDark",
      light: {
        color: "text",

        fontFamily: "body",
        fontWeight: "body",
        lineHeight: "body",
      },
    },
    a: {
      color: "primary",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    footerHeading: {
      color: "textLight",
      fontFamily: "brand",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 3,
      textTransform: "uppercase",
      mb: 5,
    },
    jobsListCard: {
      dark: {
        title: {
          color: "textLight",
        },
        subtitle: {
          color: "textLight",
        },
        button: {
          border: "3px solid red !important",
        },
      },
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: `0 1px 2px rgba(0,0,0,0.07),
      0 2px 4px rgba(0,0,0,0.07),
      0 4px 8px rgba(0,0,0,0.07),
      0 8px 16px rgba(0,0,0,0.07),
      0 16px 32px rgba(0,0,0,0.07),
      0 32px 64px rgba(0,0,0,0.07)`,
      backgroundColor: "background",
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: "1px solid",
      borderColor: "muted",
      backgroundColor: "background",
    },
    translucent: {
      pt: [3, 3, 3, 4, 4],
      pb: [5, 5, 5, 5, 5],
      borderRadius: 6,
      backgroundColor: "hsla(1,0%, 90%, 0.85)",
      // background: "#283048  /* fallback for old browsers */",
      background:
        "linear-gradient(to left, hsla(225, 38%, 96%, 0.9), hsla(195, 30%, 95%, 0.97)  ) /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */",

      // borderWidth: "0.125rem",
      // borderStyle: "solid",
      // borderColor: "primary100",
    },
    jobsListCard: {
      dark: {
        root: {
          backgroundColor: "neutral600",
        },
      },
      light: {
        root: {
          backgroundColor: "neutral200",
        },
      },
    },
  },
  images: {
    avatar: {
      xxs: {
        width: 24,
        height: 24,
        minWidth: 24,
        minHeight: 24,
        borderRadius: 99999,
        boxShadow: `
        0 0.6px 3.9px -3px rgba(0, 0, 0, 0.067),
        0 1.4px 9.3px -3px rgba(0, 0, 0, 0.097),
        0 2.6px 17.5px -3px rgba(0, 0, 0, 0.12),
        0 4.7px 31.3px -3px rgba(0, 0, 0, 0.143),
        0 8.8px 58.5px -3px rgba(0, 0, 0, 0.173),
        0 21px 140px -3px rgba(0, 0, 0, 0.24)
      `,
      },
      xs: {
        width: 36,
        height: 36,
        minWidth: 36,
        minHeight: 36,
        borderRadius: 99999,
        boxShadow: `
        0 0.6px 3.9px -3px rgba(0, 0, 0, 0.067),
        0 1.4px 9.3px -3px rgba(0, 0, 0, 0.097),
        0 2.6px 17.5px -3px rgba(0, 0, 0, 0.12),
        0 4.7px 31.3px -3px rgba(0, 0, 0, 0.143),
        0 8.8px 58.5px -3px rgba(0, 0, 0, 0.173),
        0 21px 140px -3px rgba(0, 0, 0, 0.24)
      `,
      },
      sm: {
        width: 48,
        height: 48,
        minWidth: 48,
        minHeight: 48,
        borderRadius: 99999,
        boxShadow: `
        0 0.6px 3.9px -3px rgba(0, 0, 0, 0.067),
        0 1.4px 9.3px -3px rgba(0, 0, 0, 0.097),
        0 2.6px 17.5px -3px rgba(0, 0, 0, 0.12),
        0 4.7px 31.3px -3px rgba(0, 0, 0, 0.143),
        0 8.8px 58.5px -3px rgba(0, 0, 0, 0.173),
        0 21px 140px -3px rgba(0, 0, 0, 0.24)
      `,
      },
      md: {
        width: 64,
        height: 64,
        minWidth: 64,
        minHeight: 64,
        borderRadius: 99999,
        boxShadow: `
        0 0.6px 3.9px -3px rgba(0, 0, 0, 0.067),
        0 1.4px 9.3px -3px rgba(0, 0, 0, 0.097),
        0 2.6px 17.5px -3px rgba(0, 0, 0, 0.12),
        0 4.7px 31.3px -3px rgba(0, 0, 0, 0.143),
        0 8.8px 58.5px -3px rgba(0, 0, 0, 0.173),
        0 21px 140px -3px rgba(0, 0, 0, 0.24)
      `,
      },
      lg: {
        width: 72,
        height: 72,
        minWidth: 72,
        minHeight: 72,
        borderRadius: 99999,
        boxShadow: `
        0 0.6px 3.9px -3px rgba(0, 0, 0, 0.067),
        0 1.4px 9.3px -3px rgba(0, 0, 0, 0.097),
        0 2.6px 17.5px -3px rgba(0, 0, 0, 0.12),
        0 4.7px 31.3px -3px rgba(0, 0, 0, 0.143),
        0 8.8px 58.5px -3px rgba(0, 0, 0, 0.173),
        0 21px 140px -3px rgba(0, 0, 0, 0.24)
      `,
      },
      xl: {
        width: 96,
        height: 96,
        minWidth: 96,
        minHeight: 96,
        borderRadius: 99999,
        boxShadow: `
        0 0.6px 3.9px -3px rgba(0, 0, 0, 0.067),
        0 1.4px 9.3px -3px rgba(0, 0, 0, 0.097),
        0 2.6px 17.5px -3px rgba(0, 0, 0, 0.12),
        0 4.7px 31.3px -3px rgba(0, 0, 0, 0.143),
        0 8.8px 58.5px -3px rgba(0, 0, 0, 0.173),
        0 21px 140px -3px rgba(0, 0, 0, 0.24)
      `,
      },
    },
  },
  badges: {
    square: {
      contained: {
        primary: {
          fontSize: 2,
          fontFamily: "brand",
          backgroundColor: "text",
          color: "textLight",
          lineHeight: "normal",
          py: 2,
          px: 2,
          borderRadius: 0,
        },
        secondary: {
          color: "background",
          bg: "primary",
        },
      },
      outlined: {
        primary: {
          color: "primary",
          bg: "transparent",
          boxShadow: "inset 0 0 0 1px",
        },
        secondary: {
          color: "secondary",
          bg: "transparent",
          boxShadow: "inset 0 0 0 1px",
        },
      },
    },
    circle: {
      contained: {
        primary: {
          color: "background",
          bg: "primary",
          borderRadius: "9999px",
        },
        secondary: {
          color: "background",
          bg: "primary",
          borderRadius: "9999px",
        },
      },
      outlined: {
        primary: {
          color: "primary",
          bg: "transparent",
          boxShadow: "inset 0 0 0 1px",
          borderRadius: "9999px",
        },
        secondary: {
          color: "secondary",
          bg: "transparent",
          boxShadow: "inset 0 0 0 1px",
          borderRadius: "9999px",
        },
      },
    },
  },
  layout: {
    padded: {
      maxWidth: "1000px",
    },
    paddedSmDesktop: {
      maxWidth: "1200px",
    },
    paddedMdDesktop: {
      maxWidth: "1400px",
    },
    paddedMdLgDesktop: {
      maxWidth: "1600px",
    },
    paddedLgDesktop: {
      maxWidth: "1800px",
    },
    fullWidth: {
      noHeight: {
        width: "100%",
      },
      20: {
        width: "100%",
        height: "30vh",
      },
      30: {
        width: "100%",
        height: "30vh",
      },
      40: {
        width: "100%",
        height: "40vh",
      },
      45: {
        width: "100%",
        height: "45vh",
      },
      50: {
        width: "100%",
        height: "50vh",
      },
      60: {
        width: "100%",
        height: "60vh",
      },
      70: {
        width: "100%",
        height: "70vh",
      },
      80: {
        width: "100%",
        height: "80vh",
      },
      90: {
        width: "100%",
        height: "90vh",
      },
      100: {
        width: "100%",
        height: "100vh",
      },
      fullHeightMinusHeader: {
        minWidth: "100%",
        minHeight: "calc(100vh - 64px)",
      },
    },
    header: {
      color: "#000",
      bg: "white",
      px: "2rem",
      py: "1rem",
    },
    footer: {},
  },
  // sizes: {
  //   container: '1000px'
  // },
  links: {
    text: {
      textDecoration: "none",
      color: "topNavText",
      fontFamily: "brand",
      lineHeight: "heading",
      fontWeight: "mid",
      textAlign: "center",
      fontSize: 3,
      my: 1,
      "&:hover": {
        color: "highlight",
      },
      caps: {
        textTransform: "uppercase",
      },
    },
    contained: {
      // border: "1px solid red",
      bg: "action",
      minWidth: "100%",
      width: "100%",
      px: 3,
      color: "white",
      textAlign: "center",
      textDecoration: "none",
      fontFamily: "brand",
      textTransform: "uppercase",
      "&:hover": {
        cursor: "pointer",
        bg: "text",
        color: "action",
      },
    },
    outlined: {
      bg: "primary",
      minWidth: "100%",
      width: "100%",
      px: 4,
      py: 2,
      color: "white",
      textAlign: "center",
      textDecoration: "none",
      fontFamily: "brand",
      caps: {
        textTransform: "uppercase",
      },
    },
    nav: {
      fontFamily: "brand",
      margin: "0 0.75rem",
      textDecoration: "none",
      color: "topNavText",
      whiteSpace: "nowrap",
      "&:hover": {
        cursor: "pointer",
      },
    },
    signOutNavButton: {
      margin: "0 0.75rem",
      textDecoration: "none",
      color: "topNav.text",
      whiteSpace: "nowrap",
      fontFamily: "brand",
      "&:hover": {
        textDecoration: "none",
        color: "topNav.text",
        cursor: "pointer",
      },
      "&:active": {
        textDecoration: "none",
        color: "topNav.text",
        cursor: "pointer",
      },
      "&:focus": {
        color: "topNav.text",
        textDecoration: "none",
        cursor: "pointer",
      },
      "&:visited": {
        textDecoration: "none",
        color: "topNav.text",
        cursor: "pointer",
      },
    },
    dashboardMenu: {
      margin: "0 0.85rem",
      textDecoration: "none",
      color: "topNav.text",
      fontFamily: "brand",
      "&:hover": {
        cursor: "pointer",
      },
    },
    sideDrawerMenuItemATag: {
      color: "textLight",
      textDecoration: "none",
      fontFamily: "brand",
    },
    footerListItem: {
      color: "textLight",
      textDecoration: "none",
      fontFamily: "brand",
      lineHeight: "heading",
      fontWeight: "mid",
      fontSize: 3,
      my: 1,
      "&:hover": {
        color: "highlight",
      },
    },
    standard: {
      color: "text",
      textDecoration: "underline",
      fontFamily: "brand",
      lineHeight: "heading",
      fontWeight: "mid",
      fontSize: 3,
      my: 1,
      "&:hover": {
        color: "highlight",
      },
    },
    underlined: {
      textDecorationLine: "underline",
      fontSize: [3, 3, 3, 4, 4, 4, 4],
      fontFamily: "brand",
    },
  },
};

export type ExactTheme = typeof themeUI;

export const useTheme = useThemeUI as unknown as () => ExactContextValue;
