import { ReactElement } from "react";
import { Element } from "react-scroll";
import { Heading, Box } from "@theme-ui/components";

export default function CardHeader(): ReactElement {
  return (
    <Box sx={{ pt: ["1rem"], px: "0.75rem" }}>
      <Element name="scroll-to-element" className="element">
        <Box sx={{ pt: ["2rem", "2rem", "2rem", 0], px: "0.75rem" }}>
          <Heading
            as="h2"
            sx={{
              // pt: "3rem",
              fontSize: ["2.1rem", "2.3rem", "2.3rem"],
              color: "#002255",
              fontWeight: 400,
              fontFamily: "Jubilat-Regular",
            }}
          >
            Your Yearly
          </Heading>
          <Heading
            as="h2"
            sx={{
              fontSize: ["2.1rem", "2.3rem", "2.3rem"],
              color: "#81BB56",
              fontWeight: 400,

              fontFamily: "Jubilat-Regular",
            }}
          >
            Emissions Report
          </Heading>
        </Box>
      </Element>
    </Box>
  );
}
