import CalculatorHero from "./components/CalculatorHero/CalculatorHero";
import EmissionsReport from "./components/EmissionsReport/EmissionsReport";
import Layout from "./components/Layout/Layout";
import axios from "axios";
import "./index.css";
import React, { useState } from "react";
import { scroller } from "react-scroll";
import { PeriodType } from "./types/Period.type";
import { DistanceUnitsType } from "./types/DistanceUnits.type";
import { DistanceCalculationType } from "./types/DistanceCalculationType.type";
import { FormOnSubmitDataType } from "./types/FormOnSubmitData.type";
import { DeliveryType } from "./types/Delivery.type";
import { ReportType } from "./types/Report.Type";
import queryString from "query-string";

function convertDistanceMilesToMeters(distanceMiles: number): number {
  const meters = distanceMiles * 1.60934 * 1000;
  return meters;
}

function convertDistanceKmToMeters(distanceKm: number): number {
  const meters = distanceKm * 1000;
  return meters;
}

export default function App(): React.ReactElement {
  const [reportId, setReportId] = useState<undefined | string>(undefined);
  const [reportObj, setReportObj] = useState<ReportType | undefined>(undefined);
  const [distance, setDistance] = useState<number>(3);
  const [distanceUnit, setDistanceUnit] = useState<DistanceUnitsType>("KILOMETERS");
  const [distanceType, setDistanceType] = useState<DistanceCalculationType>("DISTANCE");
  const [period, setPeriod] = useState<PeriodType>("MONTHLY");
  const [weight, setWeight] = useState<number>(3);
  const [loading, setLoading] = useState<boolean>(false);

  // console.log("distanceUnit", distanceUnit);

  function formatFormDataToPayload(data: FormOnSubmitDataType): { deliveries: DeliveryType } {
    const numberOfParcels = data.numberOfParcels;
    let numberOfParcelsBasedOnPeriod = numberOfParcels;

    if (period === "YEARLY") {
      numberOfParcelsBasedOnPeriod = numberOfParcels;
    } else if (period === "WEEKLY") {
      numberOfParcelsBasedOnPeriod = numberOfParcels * 52;
    } else if (period === "MONTHLY") {
      numberOfParcelsBasedOnPeriod = numberOfParcels * 12;
    }

    const parcelWidthCM = data.parcel.width;
    const parcelHeightCM = data.parcel.height;
    const parcelLengthCM = data.parcel.length;
    const volumeCubicCM = parcelWidthCM * parcelHeightCM * parcelLengthCM;

    // console.log("parcelWidthCM", parcelWidthCM);
    // console.log("parcelHeightCM", parcelHeightCM);
    // console.log("parcelLengthCM", parcelLengthCM);
    // console.log("volumeCubicMetres", volumeCubicCM);

    let distanceMeters: string | number = "";

    if (typeof distance === "number" && distanceUnit === "KILOMETERS") {
      distanceMeters = convertDistanceKmToMeters(distance);
    }
    if (typeof distance === "number" && distanceUnit === "MILES") {
      distanceMeters = convertDistanceMilesToMeters(distance);
    }

    // const deliveryArray = [];

    // for (let i = 0; i < numberOfParcelsBasedOnPeriod; i++) {
    //   deliveryArray.push({
    //     origin: distanceType === "POSTCODE" ? String(data.origin) : "",
    //     destination: distanceType === "POSTCODE" ? String(data.destination) : "",
    //     parcel: {
    //       volume: volumeCubicCM,
    //       weight: weight,
    //       distance: distanceMeters,
    //     },
    //   });
    // }

    const payload = {
      deliveries: {
        origin: distanceType === "POSTCODE" ? String(data.origin) : "",
        destination: distanceType === "POSTCODE" ? String(data.destination) : "",
        numberOfParcels: numberOfParcelsBasedOnPeriod,
        parcel: {
          volume: volumeCubicCM,
          weight: weight,
          distance: distanceMeters,
        },
      },
    };

    // console.log("response payload", payload);
    return payload;
  }

  async function onSubmit(data: FormOnSubmitDataType) {
    setLoading(true);
    const payload = formatFormDataToPayload(data);
    // console.log("payload", payload);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/simple-report`,
        payload
      );
      setReportObj(response.data);
      setReportId(response.data.report.id);
      scroller.scrollTo("scroll-to-element", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    } catch (error) {
      // console.log("error", error);
    }
    setLoading(false);
  }

  // function useQuery() {
  //   return new URLSearchParams(useLocation().search);
  // }

  // const query = useQuery();
  const parsed = queryString.parse(location.search);
  // console.log("parsed", parsed);
  // setReportId(parsed?.id);

  async function getReports() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/reports/${parsed?.id}`);
      setReportObj(response.data);
      setReportId(response.data.report.id);
      scroller.scrollTo("scroll-to-element", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    } catch (error) {
      // console.log("error", error);
    }
  }

  if (parsed?.id) {
    getReports();
  }
  // useEffect(() => {
  //   getReports();
  // }, [reportId]);

  return (
    <Layout>
      <CalculatorHero
        onSubmit={onSubmit}
        distance={distance}
        setDistance={setDistance}
        period={period}
        setPeriod={setPeriod}
        weight={weight}
        setWeight={setWeight}
        distanceUnit={distanceUnit}
        setDistanceUnit={setDistanceUnit}
        distanceType={distanceType}
        setDistanceType={setDistanceType}
        loading={loading}
      />
      <div>{reportObj && <EmissionsReport reportId={reportId} reportObj={reportObj} />}</div>
    </Layout>
  );
}
