import { ReactElement } from "react";
import { Card, Flex, Box } from "@theme-ui/components";
import { ReportType } from "src/types/Report.Type";
import EmissionsTable from "./EmissionsTable/EmissionsTable";
import EmissionsEquivalentsMobileGraphic from "./EmissionsEquivalents/EmissionsEquivalentsGraphic";
// import EmissionsEquivelentsTabletDesktopGraphic from "./EmissionsEquivelents/EmissionsEquivelentsTabletDesktopGraphic";
import EmissionsEquivalentsHeader from "./EmissionsEquivalents/EmissionsEquivalentsHeader";
import CardHeader from "./CardHeader/CardHeader";
import CardFooter from "./CardFooter/CardFooter";
import ButtonsRow from "./ButtonsRow/ButtonsRow";

interface Props {
  reportId: string | undefined;
  reportObj: ReportType;
}

export default function EmissionsReport({ reportObj }: Props): ReactElement {
  // console.log("reportObjID", reportObj.report.id);
  // console.log("reportObj", reportObj);

  // const co2ReductionPercentage =
  //   (Number(reportObj?.report?.savings?.co2?.value) /
  //     Number(reportObj?.report?.totals?.traditional?.co2?.value)) *
  //   100;
  // const pm2dot5ReductionPercentage =
  //   (Number(reportObj?.report?.savings["pm2.5"].value) /
  //     Number(reportObj?.report?.totals?.traditional["pm2.5"]?.value)) *
  //   100;

  return (
    <Flex
      sx={{
        flexDirection: ["column", "column", "column", "column", "column", "row"],
        justifyContent: "center",
        paddingTop: [0, 0, "4.5rem", "2rem", "3rem", "4rem"],
        paddingBottom: [0, 0, "2rem"],
        px: [0, 0, 0, "2rem"],
        minHeight: "100vh",
        backgroundColor: "#19272E",
        // borderTop: "3px solid #19272E",
      }}
    >
      <Card
        sx={{
          // padding: [6],
          marginX: ["auto", "auto", "auto", "auto", "auto", 0],
          borderRadius: [0, 0, "8px"],
          paddingX: 0,
          paddingBottom: [5],
          // border: "1px solid red",
          minWidth: "320px",
          maxWidth: "420px",
          backgroundColor: "hsl(324,16%,93%)",
          maxHeight: ["675px", "675px", "700px"],
        }}
      >
        <CardHeader />
        <EmissionsTable reportObj={reportObj} />
        <CardFooter />
        <ButtonsRow />
      </Card>
      <Box sx={{ marginLeft: [0, 0, 0, 0, 0, "4rem"] }}>
        <EmissionsEquivalentsHeader />
        <EmissionsEquivalentsMobileGraphic reportObj={reportObj} />
        {/* <EmissionsEquivelentsTabletDesktopGraphic reportObj={reportObj} /> */}
      </Box>
    </Flex>
  );
}
