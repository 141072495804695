import React, { ChangeEventHandler, ChangeEvent } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Controller, useForm } from "react-hook-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Flex, Text } from "@theme-ui/components";
import * as yup from "yup";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { PeriodType } from "../../../types/Period.type";
import { DistanceUnitsType } from "../../../types/DistanceUnits.type";
import { DistanceCalculationType } from "../../../types/DistanceCalculationType.type";
import { FormOnSubmitDataType } from "../../../types/FormOnSubmitData.type";
import { yupResolver } from "@hookform/resolvers/yup";

const useStyles = makeStyles({
  "@Global .MuiInputBase-adornedEnd": {
    paddingLeft: 0,
  },
  rootTextSmall: {
    height: "48.5px",
  },
  toggleButtonGroupRoot: {
    height: "56px",
  },
  toggleButtonDistanceGroupRoot: {
    height: "40px",
    margin: 0,
  },
  toggleButtonDistanceGroupGrouped: {
    height: "40px",
    margin: 0,
    padding: "0px 6px",
    border: "none",
  },
  toggleButtonDistanceButton: {},
  button: {
    width: "100%",
    borderRadius: "6px",
    backgroundColor: "#8fcb6f",
    boxShadow: "4px 4px 10px 5px rgb(0 0 0 / 12%)",
    WebkitTransition: "-webkit-transform 200ms ease",
    transition: "-webkit-transform 200ms ease",
    fontFamily: "Poppins, sans-serif",
    fontSize: "1.3rem",
    color: "white",
    "&:hover": {
      backgroundColor: "hsl(218,57%,27%)",
    },
  },
  sliderInputBox: {
    marginLeft: "1.5rem",
    width: "240px",
  },
  distanceSliderInputBox: {
    width: "240px",
    marginLeft: "1.5rem",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
  },
});

const CustomSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
    backgroundImage: "linear-gradient(.25turn, #1d2b4d, #639f4d)",
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundImage: "linear-gradient(.25turn, #1d2b4d, #639f4d)",
  },
})(Slider);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#6bc34a",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#6bc34a",
    },
    "& .MuiOutlinedInput-root": {
      // "& fieldset": {
      //   borderColor: "red",
      // },
      "&:hover fieldset": {
        borderColor: "#6bc34a",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6bc34a",
      },
    },
  },
})(TextField);

interface Props {
  onSubmit: (data: FormOnSubmitDataType) => Promise<void>;
  distance: number | string;
  setDistance: React.Dispatch<React.SetStateAction<number | string>>;
  period: PeriodType;
  setPeriod: React.Dispatch<React.SetStateAction<PeriodType>>;
  weight: number;
  setWeight: React.Dispatch<React.SetStateAction<number>>;
  distanceUnit: string;
  setDistanceUnit: React.Dispatch<React.SetStateAction<DistanceUnitsType>>;
  distanceType: DistanceCalculationType;
  setDistanceType: React.Dispatch<React.SetStateAction<DistanceCalculationType>>;
  loading: boolean;
}

const defaultValues = {
  origin: undefined,
  destination: undefined,
  numberOfParcels: 10,
  parcel: {
    height: 10,
    width: 10,
    length: 20,
    weight: 3,
    distance: 3,
  },
};

const units = {
  distance: "km",
  measurement: "cm",
  weight: "kg",
};

const calculatorSchemaForPostCode = yup.object({
  origin: yup
    .string()
    .required("Origin postcode is required")
    .matches(
      /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
      "Origin must be a valid UK postcode"
    ),
  destination: yup
    .string()
    .required("Destination postcode is required")
    .matches(
      /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
      "Destination must be a valid UK postcode"
    ),
  numberOfParcels: yup.number().required("Number of parcels is required").min(1).max(500),
  parcel: yup.object({
    height: yup.number().required("Parcel height is required").min(1).max(100),
    width: yup.number().required("Parcel width is required").min(1).max(100),
    length: yup.number().required("Parcel length is required").min(1).max(100),
    weight: yup.number().required("Parcel weight is required").min(0.1).max(100),
    distance: yup.string(),
  }),
});

const calculatorSchemaForDistance = yup.object({
  numberOfParcels: yup.number().required("Number of parcels is required").min(1).max(500),
  parcel: yup.object({
    height: yup.number().required("Parcel height is required").min(1).max(100),
    width: yup.number().required("Parcel width is required").min(1).max(100),
    length: yup.number().required("Parcel length is required").min(1).max(100),
    weight: yup.number().required("Parcel weight is required").min(0.1).max(100),
    distance: yup.number().required("Parcel distance is required").min(0.1).max(250),
  }),
});

export default function CalculatorForm({
  onSubmit,
  distance,
  setDistance,
  period,
  setPeriod,
  weight,
  setWeight,
  distanceUnit,
  setDistanceUnit,
  distanceType,
  setDistanceType,
  loading,
}: Props): React.ReactElement {
  const classes = useStyles();
  const { handleSubmit, control, formState } = useForm<FormOnSubmitDataType>({
    defaultValues: defaultValues,
    resolver: yupResolver(
      distanceType === "POSTCODE" ? calculatorSchemaForPostCode : calculatorSchemaForDistance
    ),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const handlePeriodChange = (event: React.SyntheticEvent, newPeriod: PeriodType) => {
    event.preventDefault();
    setPeriod(newPeriod);
  };

  const handleDistanceUnitChange = (
    event: React.SyntheticEvent,
    newDistanceUnit: DistanceUnitsType
  ) => {
    event.preventDefault();
    // console.log("newDistanceUnit", newDistanceUnit);
    setDistanceUnit(newDistanceUnit);
  };

  const handleDistanceTypeChange = (
    event: React.SyntheticEvent,
    newDistanceType: DistanceCalculationType
  ) => {
    event.preventDefault();
    if (newDistanceType === "POSTCODE") {
      // console.log("POSTCODE distance type");
      setDistance("");
    }
    if (newDistanceType === "DISTANCE") {
      // console.log("DISTANCE distance type");
      setDistance(3);
    }
    setDistanceType(newDistanceType);
  };

  const handleWeightSliderChange: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = Number(event.target.innerText);
    setWeight(newValue);
  };

  const handleWeightInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeight(Number(event.target.value));
  };

  const handleWeightBlur = () => {
    if (weight < 0) {
      setWeight(0);
    } else if (weight > 100) {
      setWeight(100);
    }
  };

  const handleDistanceSliderChange: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = Number(event.target.innerText);
    setDistance(newValue);
  };

  const handleDistanceInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDistance(Number(event.target.value));
  };

  const handleDistanceBlur = () => {
    if (distance < 0) {
      setDistance(0);
    } else if (distance > 100) {
      setDistance(100);
    }
  };

  // console.log("formState", formState);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <Flex
          sx={{
            flexDirection: ["column", "column", "column", "column", "column", "row"],
            mb: [0, 0, 0],
          }}
        >
          <Box sx={{ minWidth: "100%" }}>
            <Box sx={{ mt: 3, mb: "0.75rem", textAlign: "center" }}>
              <Text
                sx={{
                  color: "textDark",
                  fontFamily: "Poppins",
                  fontSize: ["0.79rem", "0.8rem", "0.9rem"],
                }}
              >
                Enter the dimensions of your average parcel
              </Text>
            </Box>
            <Flex
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  marginY: "0.5rem",
                  marginRight: ["0.5rem", "0.5rem", "0.5rem", "0.5rem", "0.5rem", "0.5rem"],
                  width: "100%",
                }}
              >
                <Controller
                  render={({ field }) => (
                    <CssTextField
                      {...field}
                      variant="outlined"
                      label="Parcel Width"
                      fullWidth
                      inputProps={{
                        type: "number",
                        min: 1,
                        step: 1,
                        max: 60,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">{units.measurement}</InputAdornment>
                        ),
                      }}
                    />
                  )}
                  name="parcel.width"
                  control={control}
                />
              </Box>
              <Box
                sx={{
                  marginY: "0.5rem",
                  marginX: ["0.5rem", "0.5rem", "0.5rem", "0.5rem", "0.5rem", "0.5rem"],
                  width: "100%",
                }}
              >
                <Controller
                  name="parcel.height"
                  control={control}
                  render={({ field }) => (
                    <CssTextField
                      {...field}
                      variant="outlined"
                      label="Parcel Height"
                      fullWidth
                      inputProps={{
                        type: "number",
                        min: 1,
                        step: 1,
                        max: 60,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">{units.measurement}</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  marginY: "0.5rem",
                  marginLeft: ["0.5rem", "0.5rem", "0.5rem", "0.5rem", "0.5rem", "0.5rem"],
                  width: "100%",
                }}
              >
                <Controller
                  name="parcel.length"
                  control={control}
                  render={({ field }) => (
                    <CssTextField
                      {...field}
                      variant="outlined"
                      label="Parcel Length"
                      fullWidth
                      inputProps={{
                        type: "number",
                        min: 1,
                        step: 1,
                        max: 60,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">{units.measurement}</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Flex sx={{ flexDirection: "column" }}>
          {formState?.errors?.parcel?.width?.message && (
            <div>
              <Text sx={{ fontFamily: "body", color: "#f44336", fontSize: 2 }}>
                {formState?.errors?.parcel?.width?.message}
              </Text>
            </div>
          )}
          {formState?.errors?.parcel?.height?.message && (
            <div>
              <Text sx={{ fontFamily: "body", color: "#f44336", fontSize: 2 }}>
                {formState?.errors?.parcel?.height?.message}
              </Text>
            </div>
          )}
          {formState?.errors?.parcel?.length?.message && (
            <div>
              <Text sx={{ fontFamily: "body", color: "#f44336", fontSize: 2 }}>
                {formState?.errors?.parcel?.length?.message}
              </Text>
            </div>
          )}
        </Flex>
        <Box sx={{ mt: 2, mb: 4 }}>
          <Box sx={{ mb: "1rem", textAlign: "center" }}>
            <Text sx={{ color: "textDark", fontSize: ["0.79rem", "0.8rem", "0.9rem"] }}>
              Enter number of parcels in the delivery period
            </Text>
          </Box>

          <Flex sx={{ mt: 0, mb: 0, justifyContent: "space-between" }}>
            <Box
              sx={{
                width: ["33%", "42%"],
                paddingRight: "0.5rem",
              }}
            >
              <Controller
                render={({ field }) => (
                  <CssTextField
                    {...field}
                    variant="outlined"
                    label="Number"
                    fullWidth
                    inputProps={{
                      type: "number",
                      min: 1,
                      step: 1,
                      max: 500,
                    }}
                  />
                )}
                name="numberOfParcels"
                control={control}
              />
            </Box>
            <Flex sx={{ alignItems: "center", justifyContent: "flex-end", mb: 3, width: "66%" }}>
              <ToggleButtonGroup
                color="primary"
                value={period}
                exclusive
                onChange={handlePeriodChange}
                classes={{ root: classes.toggleButtonGroupRoot }}
              >
                <ToggleButton value="WEEKLY">Per Week</ToggleButton>
                <ToggleButton value="MONTHLY">Per Month</ToggleButton>
                <ToggleButton value="YEARLY">Per Year</ToggleButton>
              </ToggleButtonGroup>
            </Flex>
          </Flex>
          <Flex sx={{ flexDirection: "column" }}>
            {formState?.errors?.numberOfParcels?.message && (
              <div>
                <Text sx={{ fontFamily: "body", color: "#f44336", fontSize: 2 }}>
                  {formState?.errors?.numberOfParcels?.message}
                </Text>
              </div>
            )}
          </Flex>
        </Box>

        <Box sx={{ textAlign: "center" }}>
          <Text sx={{ color: "textDark", fontSize: ["0.79rem", "0.8rem", "0.9rem"] }}>
            Average parcel weight
          </Text>
          <Flex sx={{ alignItems: "center", mt: "0.3rem", pl: "4" }}>
            <CustomSlider
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              onChange={handleWeightSliderChange}
              min={0.5}
              step={0.5}
              max={100}
            />
            <CssTextField
              className={classes.sliderInputBox}
              value={weight}
              margin="dense"
              variant="outlined"
              onChange={handleWeightInputChange}
              onBlur={handleWeightBlur}
              inputProps={{
                min: 0.5,
                step: 0.5,
                max: 100,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{units.weight}</InputAdornment>,
              }}
            />
          </Flex>
          <Flex sx={{ flexDirection: "column" }}>
            {formState?.errors?.parcel?.weight?.message && (
              <div>
                <Text sx={{ fontFamily: "body", color: "#f44336", fontSize: 2 }}>
                  {formState?.errors?.parcel?.weight?.message}
                </Text>
              </div>
            )}
          </Flex>
        </Box>
        <Box sx={{ textAlign: "center", mt: 4, mb: 6 }}>
          <Text sx={{ color: "textDark", fontSize: ["0.79rem", "0.8rem", "0.9rem"] }}>
            Average Distance
          </Text>
          <Flex sx={{ mt: "0.8rem", justifyContent: "center" }}>
            <ToggleButtonGroup
              color="primary"
              value={distanceType}
              exclusive
              onChange={handleDistanceTypeChange}
              classes={{ root: classes.toggleButtonGroupRoot }}
            >
              <ToggleButton value="DISTANCE">By Distance</ToggleButton>
              <ToggleButton value="POSTCODE">By Postcode</ToggleButton>
            </ToggleButtonGroup>
          </Flex>
          {distanceType === "DISTANCE" ? (
            <Flex sx={{ flexDirection: "column" }}>
              <Flex
                sx={{
                  mt: 2,
                  pl: "4",
                  alignItems: "center",
                }}
              >
                <CustomSlider
                  valueLabelDisplay="auto"
                  aria-label="distance-slider"
                  onChange={handleDistanceSliderChange}
                  min={1}
                  step={1}
                  max={250}
                />
                <CssTextField
                  className={classes.distanceSliderInputBox}
                  variant="outlined"
                  value={distance}
                  margin="dense"
                  onChange={handleDistanceInputChange}
                  onBlur={handleDistanceBlur}
                  inputProps={{
                    min: 1,
                    step: 1,
                    max: 250,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ToggleButtonGroup
                          color="primary"
                          value={distanceUnit}
                          exclusive
                          onChange={handleDistanceUnitChange}
                          classes={{
                            root: classes.toggleButtonDistanceGroupRoot,
                            grouped: classes.toggleButtonDistanceGroupGrouped,
                          }}
                        >
                          <ToggleButton value="KILOMETERS">KM</ToggleButton>
                          <ToggleButton value="MILES">Mi</ToggleButton>
                        </ToggleButtonGroup>
                      </InputAdornment>
                    ),
                  }}
                />
              </Flex>
              <Flex sx={{ flexDirection: "column" }}>
                {formState?.errors?.parcel?.distance?.message && (
                  <div>
                    <Text sx={{ fontFamily: "body", color: "#f44336", fontSize: 2 }}>
                      {formState?.errors?.parcel?.distance?.message}
                    </Text>
                  </div>
                )}
              </Flex>
            </Flex>
          ) : (
            <Flex sx={{ flexDirection: "column" }}>
              <Flex sx={{ mt: 4 }}>
                <Box sx={{ my: "0.5rem", mr: "0.25rem" }}>
                  <Controller
                    render={({ field }) => (
                      <CssTextField {...field} variant="outlined" label="Origin" fullWidth />
                    )}
                    name="origin"
                    control={control}
                  />
                </Box>
                <Box my={"0.5rem"} ml="0.25rem">
                  <Controller
                    render={({ field }) => (
                      <CssTextField {...field} variant="outlined" label="Destination" fullWidth />
                    )}
                    name="destination"
                    control={control}
                  />
                </Box>
              </Flex>
              <Flex sx={{ flexDirection: "column" }}>
                {formState?.errors?.origin?.message && (
                  <div>
                    <Text sx={{ fontFamily: "body", color: "#f44336", fontSize: 2 }}>
                      {formState?.errors?.origin?.message}
                    </Text>
                  </div>
                )}
                {formState?.errors?.destination?.message && (
                  <div>
                    <Text sx={{ fontFamily: "body", color: "#f44336", fontSize: 2 }}>
                      {formState?.errors?.destination?.message}
                    </Text>
                  </div>
                )}
              </Flex>
            </Flex>
          )}
        </Box>
        {loading ? (
          <Flex sx={{ justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Flex>
        ) : (
          <Button className={classes.button} variant="contained" type="submit">
            GET REPORT
          </Button>
        )}
      </form>
    </div>
  );
}
