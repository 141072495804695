import { ReactElement } from "react";
import { Heading, Box } from "@theme-ui/components";

const titleFontSize = {
  fontSize: ["1.8rem", "1.8rem", "1.9rem", "2.1rem"],
};

const firstTitleStyle = {
  ...titleFontSize,
  color: "#fff",
  fontFamily: "Jubilat-Regular",
};
const secondTitleStyle = {
  ...titleFontSize,
  color: "#81BB56",
  fontFamily: "Jubilat-Regular",
};
const subtitleStyle = {
  fontSize: ["1.1rem", "1.3rem", "1.4rem", "1.5rem"],
  color: "#81BB56",
  fontWeight: 400,
  fontFamily: "Poppins",
};

export default function EmissionsEquivalentsHeader(): ReactElement {
  return (
    <Box sx={{ pt: ["1rem"], px: "0.75rem", maxWidth: "420px", margin: "auto" }}>
      <Box sx={{ pt: ["2rem", "2rem", "2rem", "2rem", "2rem", "1rem"], px: "0.75rem" }}>
        <Heading as="h2" sx={firstTitleStyle}>
          {`Nice numbers!`}
        </Heading>
        <Heading as="h2" sx={secondTitleStyle}>
          {`What do they mean?`}
        </Heading>
      </Box>
      <Box sx={{ pt: ["1rem"], px: "0.75rem" }}>
        <Heading as="h3" sx={subtitleStyle}>
          They mean we’ve saved emissions equivalent to...
        </Heading>
      </Box>
    </Box>
  );
}
