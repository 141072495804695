import React, { ReactElement } from "react";

import { Image } from "theme-ui";

import { Box } from "@theme-ui/components";

// const useStyles = makeStyles({
//   root: {},
//   logo: {
//     // backgroundColor: "red",
//     pointerEvents: "none",
//     height: "60px",
//     alignSelf: "center",
//     // color: (props) => props.color,
//   },
// });

export default function Navbar(): ReactElement {
  // const classes = useStyles(props);

  return (
    <Box
      sx={{
        display: "flex",
        height: "60px",
        justifyContent: "space-between",
        paddingX: [4],
      }}
    >
      <Box sx={{ display: "grid" }}>
        <a href="https://xeroe.co.uk">
          <Image
            src={"/xeroe-logo.png"}
            variant="avatar"
            sx={{
              pointerEvents: "none",
              height: "60px",
              alignSelf: "center",
            }}
          />
        </a>
      </Box>
      <Box sx={{ display: "grid" }}>
        {/* <MenuIcon
          style={{
            pointerEvents: "none",
            height: "60px",
            alignSelf: "center",
          }}
        /> */}
      </Box>
    </Box>
  );
}
