import { ReactElement } from "react";
import { ReportType } from "src/types/Report.Type";
import { Box, Text, Flex, Image, ThemeUICSSObject } from "theme-ui";
import CommaNumber from "comma-number";

interface Props {
  reportObj: ReportType;
}

const textFlex: ThemeUICSSObject = {
  // alignItems: "center",]
  justifyContent: "center",
  flexDirection: "column",
  marginX: "1rem",
};

const topTextSharedStyle: ThemeUICSSObject = {
  display: "block",
  fontSize: ["1.4rem", "1.4rem", "1.4rem"],
  color: "textLight",
  fontFamily: "Jubilat-Regular",
  fontWeight: "400",
  whiteSpace: "nowrap",
  // width: ["100px"],
};

const bottomTextSharedStyle: ThemeUICSSObject = {
  display: "block",
  fontSize: "1rem",
  color: "textLight",
  // width: ["100px"],
};

const iconStyle = {
  width: ["30vw", "30vw", "30vw", "130px"],
};

const statRow: ThemeUICSSObject = {
  marginY: [4],
};

export default function EmissionsEquivalentsMobileGraphic({ reportObj }: Props): ReactElement {
  const homesEnergyUseForOneYear = reportObj.report.savings.homesEnergyUseForOneYear;
  const smartPhonesCharged = reportObj.report.savings.smartPhonesCharged;
  // const humanDaysLost = reportObj.report.savings.humanDaysLost;
  const forestAcresToSequesterCO2 = reportObj.report.savings.forestAcresToSequesterCO2;
  const kgCoalBurnt = reportObj.report.savings.kgCoalBurnt;
  const litersOfPetrolConsumed = reportObj.report.savings.litersOfPetrolConsumed;

  // const humanDaysLostText = "23 days";
  const homesEnergyUseForOneYearText = `${CommaNumber(
    (Number(homesEnergyUseForOneYear) * 365).toFixed(1)
  )} homes`;
  const smartPhonesChargedText = `${CommaNumber(Number(smartPhonesCharged).toFixed(0))}`;
  const forestAcresToSequesterCO2Text = `${CommaNumber(Number(forestAcresToSequesterCO2))} acres`;
  const kgCoalBurntText = `${CommaNumber(Number(kgCoalBurnt))} kilograms`;
  const litersOfPetrolConsumedText = `${CommaNumber(Number(litersOfPetrolConsumed))} litres`;

  return (
    <Box
      sx={{
        display: ["flex", "flex", "flex", "flex", "flex"],
        flexDirection: ["column", "column", "column", "column", "row"],
        justifyItems: "center",
        alignItems: "center",
        mt: [6, 6, 6, 0],
      }}
    >
      <Flex sx={{ flexDirection: "column", marginRight: [0, 0, 0, 0, "2rem", "4rem"] }}>
        {/* <Flex sx={statRow}>
          <Image sx={iconStyle} src="/emissions-icons/lifetimes.svg" alt="" />
          <Flex sx={{ ...textFlex }}>
            <Text sx={{ ...topTextSharedStyle }}>{humanDaysLostText}</Text>
            <Text sx={{ ...bottomTextSharedStyle }}>of human lifetime</Text>
          </Flex>
        </Flex> */}
        <Flex sx={statRow}>
          <Flex sx={{ ...textFlex, textAlign: "right", justifyContent: "flex-end" }}>
            <Text sx={{ ...topTextSharedStyle }}>{homesEnergyUseForOneYearText}</Text>
            <Text sx={{ ...bottomTextSharedStyle }}>worth of energy use for one day</Text>
          </Flex>
          <Image sx={iconStyle} src="/emissions-icons/house.svg" alt="" />
        </Flex>
        <Flex sx={statRow}>
          <Image sx={iconStyle} src="/emissions-icons/phone.svg" alt="" />
          <Flex sx={textFlex}>
            <Text sx={{ ...topTextSharedStyle }}>{smartPhonesChargedText}</Text>
            <Text sx={{ ...bottomTextSharedStyle }}>smartphones charged</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex sx={{ flexDirection: "column" }}>
        <Flex sx={statRow}>
          <Flex sx={{ ...textFlex, textAlign: "right" }}>
            <Text sx={{ ...topTextSharedStyle }}>{forestAcresToSequesterCO2Text}</Text>
            <Text sx={{ ...bottomTextSharedStyle }}>of forest to sequester CO2</Text>
          </Flex>
          <Image sx={iconStyle} src="/emissions-icons/forest.svg" alt="" />
        </Flex>
        <Flex sx={statRow}>
          <Image sx={iconStyle} src="/emissions-icons/coal.svg" alt="" />
          <Flex sx={textFlex}>
            <Text sx={{ ...topTextSharedStyle }}>{kgCoalBurntText}</Text>
            <Text sx={{ ...bottomTextSharedStyle }}>of coal burnt</Text>
          </Flex>
        </Flex>
        <Flex sx={{ marginBottom: "4rem" }}>
          <Flex sx={{ ...textFlex, textAlign: "right" }}>
            <Text sx={{ ...topTextSharedStyle }}>{litersOfPetrolConsumedText}</Text>
            <Text sx={{ ...bottomTextSharedStyle }}>of petrol consumed</Text>
          </Flex>
          <Image sx={iconStyle} src="/emissions-icons/petrol.svg" alt="" />
        </Flex>
      </Flex>
    </Box>
  );
}
