import { ReactElement } from "react";
import { Text, Flex } from "theme-ui";

const textStyle = { color: "textDark", fontSize: ["0.6rem", "0.7rem"], mb: 1 };

export default function CardFooter(): ReactElement {
  return (
    <Flex sx={{ mt: 4, paddingX: [4], flexDirection: "column" }}>
      <Text sx={textStyle}>
        Emissions calculator developed in partnership with Imperial College London
      </Text>
    </Flex>
  );
}
