import React, { ReactElement } from "react";

import { Box } from "@theme-ui/components";

import Navbar from "../Navbar/Navbar";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export default function Layout({ children }: Props): ReactElement {
  return (
    <Box>
      <Navbar />
      <main>{children}</main>
    </Box>
  );
}
