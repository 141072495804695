import { Box, Card, Flex, Heading, Text } from "@theme-ui/components";
import { DistanceUnitsType } from "../../types/DistanceUnits.type";
import { DistanceCalculationType } from "../../types/DistanceCalculationType.type";
import { PeriodType } from "../../types/Period.type";

import CalculatorForm from "./CalculatorForm/CalculatorForm";
import { FormOnSubmitDataType } from "src/types/FormOnSubmitData.type";

interface Props {
  onSubmit: (data: FormOnSubmitDataType) => Promise<void>;
  distance: number;
  setDistance: React.Dispatch<React.SetStateAction<number>>;
  period: PeriodType;
  setPeriod: React.Dispatch<React.SetStateAction<PeriodType>>;
  weight: number;
  setWeight: React.Dispatch<React.SetStateAction<number>>;
  distanceUnit: DistanceUnitsType;
  setDistanceUnit: React.Dispatch<React.SetStateAction<DistanceUnitsType>>;
  distanceType: DistanceCalculationType;
  setDistanceType: React.Dispatch<React.SetStateAction<DistanceCalculationType>>;
  loading: boolean;
}

export default function CalculatorHero({
  onSubmit,
  distance,
  setDistance,
  period,
  setPeriod,
  weight,
  setWeight,
  distanceUnit,
  setDistanceUnit,
  distanceType,
  setDistanceType,
  loading,
}: Props): React.ReactElement {
  return (
    <Box
      sx={{
        height: ["100%", "100%", "100%", "100%", "calc(100vh - 60px)"],
        minHeight: [null, null, null, null, "1100px", "800px"],
        // backgroundImage: 'url("/xeroe-emissions-free-delivery.jpg")',
        backgroundImage: 'url("/XeroE-Website-Gradient.png")',
        // filter: "grayscale(1)",
        // filter: "saturate(2.2)",
        paddingTop: [6],
        backgroundPositionX: "0%, 0px, 0%",
        backgroundPositionY: "100%, 0px, 50%",
        backgroundSize: "cover, auto, 110vw",
        backdropFilter: "blur(50px)",
        "&:before": {
          content: '""',
          WebkitFilter: "blur(50px)",
          MozFilter: "blur(50px)",
          OFilter: "blur(50px)",
          MsFilter: "blur(50px)",
          filter: "blur(50px)",
        },
      }}
    >
      <Flex
        sx={{
          flexDirection: ["column", "column", "column", "column", "column", "row"],
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-200px",
            marginRight: [0, 0, 0, 0, 3, 5],
            marginBottom: [6, 6, 6, 6, 6, 0],
            paddingX: ["12px"],
            maxWidth: "400px",

            // maxWidth: ["80vw", "90vw", "85vw", "400px"],
          }}
        >
          <Heading
            sx={{
              // color: "textDark",
              fontSize: ["1.8rem", "2.1rem", "2.1rem"],
              whiteSpace: "nowrap",
              mt: [0],
              mb: [5],
              fontWeight: "400",
              textAlign: "center",
              fontFamily: "Jubilat-Regular",
              color: "#fff",
            }}
          >
            Emissions Calculator
          </Heading>
          <Text
            as="h3"
            sx={{
              color: "white",
              fontSize: [3, 4],
              // mt: [6],
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            Use our tool to calculate the emissions savings you could make by switching to XeroE.
          </Text>
          <Text
            as="h3"
            sx={{
              color: "white",
              fontSize: [3, 3],
              mt: [6],
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            Developed in collaboration with Imperial College London.
          </Text>
        </Flex>
        <Flex
          sx={{
            mt: [4],
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "-200px",
            marginLeft: [0, 0, 0, 0, 0, 3, 5],
            paddingBottom: [0, 0, 0, 4],
          }}
        >
          <Card
            sx={{
              width: "100%",
              maxWidth: "420px",
              margin: "0 auto",
              paddingX: [3, 3, 3, 5],
              paddingTop: [3],
              paddingBottom: [6],
              borderRadius: [0, 0, 0, 8, 8],
            }}
          >
            <CalculatorForm
              onSubmit={onSubmit}
              distance={distance}
              setDistance={setDistance}
              period={period}
              setPeriod={setPeriod}
              weight={weight}
              setWeight={setWeight}
              distanceUnit={distanceUnit}
              setDistanceUnit={setDistanceUnit}
              distanceType={distanceType}
              setDistanceType={setDistanceType}
              loading={loading}
            />
          </Card>
        </Flex>
      </Flex>
    </Box>
  );
}
